<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-11-12 14:23:37
 * @LastEditTime: 2021-11-17 15:55:26
 * @Description: 当前流程所有附件
 * @Param:
 * @FilePath: \JT_Web_test\src\views\businessInstance\allProcedureAnnex.vue
 -->


<template>
    <div class="allProcedureAnnex">
        <template v-for="(item, index) in annexData">
            <div :key="`annexList_${index}`">
                <h4>
                    <i class="iconfont icon30_huaban1fuben75" v-if="item.ParentID !== '0'" @click="returnSuperior(item)"></i>
                    <span>{{ item.name }}</span>
                </h4>
                <ul class="annexBox">
                    <template v-for="(k, j) in item.annexList">
                        <li :key="`annex_${j}`" :class="{'folder': k.FileType == '1' }" @click="openFolder(item, k)">
                            <div class="annexImg">
                                <svg v-if="k.FileType == '1'" class="iconwenjianjia1" aria-hidden="true">
                                    <use xlink:href="#iconwenjianjia1"></use>
                                </svg>
                                <svg v-else-if="k.FileExt == 'word'" class="iconword" aria-hidden="true">
                                    <use xlink:href="#iconword"></use>
                                </svg>
                                <svg v-else-if="k.FileExt == 'pdf'" class="iconpdf" aria-hidden="true">
                                    <use xlink:href="#iconpdf"></use>
                                </svg>
                                <svg v-else-if="k.FileExt == 'excel'" class="iconexcel" aria-hidden="true">
                                    <use xlink:href="#iconexcel"></use>
                                </svg>
                                <svg v-else-if="k.FileExt == 'txt'" class="icontxt" aria-hidden="true">
                                    <use xlink:href="#icontxt"></use>
                                </svg>
                                <svg v-else-if="k.FileExt == 'ppt'" class="iconppt" aria-hidden="true">
                                    <use xlink:href="#iconppt"></use>
                                </svg>
                                <svg v-else class="icontupian" aria-hidden="true">
                                    <use xlink:href="#icontupian"></use>
                                </svg>
                            </div>
                            <p class="fileName">
                                {{ k.FileName }}
                            </p>
                            <span>{{ k.CreateDate }}</span>
                            <div class="btns">
                                <button type="primary" class="iconfont iconicon_yulan" @click.stop="viewFileClick(k)"></button>
                                <button type="primary" class="iconfont iconxiazai1" @click.stop="download(k)"></button>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'all-procedure-annex',
    components: {},
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    data() {
        return {
            contractAnnex: [],
            modificationAnnex: [],
            adjustmentPriceAnnex: [],
            transfiniteAnnex: [],
            annexData: [{
                name: '合同信息',
                ParentID: '0',
                type: 'contractAnnex',
                annexList: [],
            }, {
                name: '合同变更',
                ParentID: '0',
                type: 'modificationAnnex',
                annexList: [],
            }, {
                name: '合同调价',
                ParentID: '0',
                type: 'adjustmentPriceAnnex',
                annexList: [],
            }, {
                name: '超限申请',
                ParentID: '0',
                type: 'transfiniteAnnex',
                annexList: [],
            }],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.init();
    },
    mounted() {},
    destroyed() {},
    methods: {
        async init() {
            const allAnnexSortData = await this.getAllAnnexSortData(this.vueFormParams.businessInstanceId, this.vueFormParams.standard_code);
            if ((allAnnexSortData.contractBusinessInstanceIds || []).length > 0) {// 合同信息附件
                this.contractAnnex = await this.getAllAnnexData(allAnnexSortData.contractBusinessInstanceIds);
            }
            if ((allAnnexSortData.modificationBusinessInstanceIds || []).length > 0) { // 合同变更附件
                this.modificationAnnex = await this.getAllAnnexData(allAnnexSortData.modificationBusinessInstanceIds);
            }
            if ((allAnnexSortData.adjustmentPriceBusinessInstanceIds || []).length > 0) {// 合同调价附件
                this.adjustmentPriceAnnex = await this.getAllAnnexData(allAnnexSortData.adjustmentPriceBusinessInstanceIds);
            }
            if ((allAnnexSortData.transfiniteBusinessInstanceIds || []).length > 0) {// 合同超限附件
                this.transfiniteAnnex = await this.getAllAnnexData(allAnnexSortData.transfiniteBusinessInstanceIds);
            }

            if (Object.keys(this.contractAnnex).length > 0) {
                this.annexData[0].annexList = this.contractAnnex.filter(item => item.ParentID === '0');
            }
            if (Object.keys(this.modificationAnnex).length > 0) {
                this.annexData[1].annexList = this.modificationAnnex.filter(item => item.ParentID === '0');
            }
            if (Object.keys(this.adjustmentPriceAnnex).length > 0) {
                this.annexData[2].annexList = this.adjustmentPriceAnnex.filter(item => item.ParentID === '0');
            }
            if (Object.keys(this.transfiniteAnnex).length > 0) {
                this.annexData[3].annexList = this.transfiniteAnnex.filter(item => item.ParentID === '0');
            }
        },

        // 文件格式处理
        fileFormatProcessing(data) {
            (data || []).forEach(item => {
                if ((item.FileName || '') !== '') {
                    const row = item.FileName.split('.');
                    item.FileExt = row[row.length - 1];
                }
            });
            return data;
        },

        // 预览
        viewFileClick(data) {
            const picturePreview = [];
            if (data.FileType === '2') {
                picturePreview.push(data.AFI_Code);
            }
            if (picturePreview.length > 0) {
                this.$toast({
                    title: true,
                    type: 'eject',
                    width: '15.4rem',
                    t_url: 'picturePreview/index',
                    extr: {
                        code: {
                            imgSrc: [],
                            picturePreview: picturePreview,
                        },
                    },
                    viewPosition: 'components',
                });
            }
        },

        // 打开文件夹
        openFolder(row, data) {
            if (data.FileType !== '1') {return;}
            row.ParentID = data.FileID;
            row.annexList = this[row.type].filter(item => item.ParentID === data.FileID);
        },

        // 返回上级文件夹
        returnSuperior(row) {
            const new_ParentID = this[row.type].filter(item => item.FileID === row.ParentID)[0].ParentID;
            row.annexList = this[row.type].filter(item => item.ParentID === new_ParentID);
            row.ParentID = row.annexList[0].ParentID;
        },

        // 文件下载
        download(data) {
            if (data.preview_url) {
                const date = new Date();
                const endDate = new Date(data.expire);
                if (date.getTime() < endDate.getTime()) {
                    if (data.mime_type.indexOf('image/') === -1) {
                        window.location.href = data.download_url;
                    } else {
                        window.location.href = data.download_url;
                    }
                } else {
                    this.getFileDetailFun(data, 1);
                }
            } else {
                this.getFileDetailFun(data, 1);
            }
        },
        // 获取文件下载预览地址
        getFileDetailFun(data, type) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + data.AFI_Code)
                .then(res => {
                    this.fileData.forEach(item => {
                        if (item.AFI_Code === data.AFI_Code) {
                            item.expire = res.expire;
                            item.mime_type = res.mime_type;
                            if (res.mime_type.indexOf('image/') === -1) {
                                item.preview_url = res.preview_url;
                                item.download_url = res.download_url;
                            } else {
                                item.preview_url = res.preview_url;
                                item.download_url = res.download_url;
                            }
                        }
                    });
                    if (type === 2) {
                        if (res.mime_type.indexOf('image/') === -1) {
                            window.open(res.preview_url, '_blank');
                        } else {
                            // this.imgUrl = res.preview_url;
                            // this.dialogVisible = true;
                            this.$toast({
                                title: true,
                                text: '图片预览',
                                type: 'eject',
                                width: '15.4rem',
                                t_url: 'picturePreview/index',
                                extr: {
                                    code: {
                                        imgSrc: [res.preview_url],
                                    },
                                    isImg: true,
                                },
                                viewPosition: 'components',
                            });
                        }
                        return;
                    }

                    if (res.mime_type.indexOf('image/') === -1) {
                        window.location.href = res.download_url;
                    } else {
                        window.location.href = res.download_url;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取所有附件分类数据
        getAllAnnexSortData(businessInstanceId, standard_code) {
            const path = `/interfaceApi/sale/contract_manager/contract_collect_file/${businessInstanceId}/${standard_code}`;
            return this.$axios.$get(path, { defEx: true });
        },
        // 获取分类下附件数据
        getAllAnnexData(filegroupinfoIds) {
            const path = '/interfaceApi/basicplatform/simpleflow/filegroupinstance/filegroupinfo_byids';
            return this.$axios.$post(path, [...filegroupinfoIds], { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped>
.allProcedureAnnex
    height: 100%
    background #F8F8F8
    padding: 0.14rem
    overflow-y: auto
    >div
        padding-top: 0.4rem
        position: relative
        margin-bottom: 0.14rem
        &:last-child
            margin-bottom: 0
        h4
            position: absolute
            top 0
            width 2.47rem
            height 0.42rem
            background url(images/allProcedureAnnex/headbj.png) no-repeat
            font-size 0.16rem
            line-height: 0.4rem
            padding-left: 0.38rem
            i
                display: inline-block
                width 0.38rem
                height: 0.38rem
                text-align: center
                cursor pointer
                position: absolute
                left 1px
                top 1px
                &:hover
                    background-color: #ecf5ff;
        .annexBox
            padding-top 0.14rem
            border 1px solid #BCBCBC
            background: #fff
            display: flex
            justify-content flex-start
            flex-wrap wrap
            li
                width calc((100%/10) - 0.4rem)
                margin: 0 0.2rem
                text-align: center
                margin-bottom: 0.14rem
                cursor pointer
                position: relative
                .btns
                    height 100%
                    width 100%
                    position: absolute
                    top 0
                    left 0
                    background: rgba(13,10,49,.8);
                    border-radius: 5px;
                    display: none
                &:hover
                    .btns
                        display: block
                .annexImg
                    width 0.44rem
                    height: 0.44rem
                    display: inline-block
                    svg
                        width 100%
                        height: 100%
                .fileName
                    font-size 0.14rem
                    line-height: 0.22rem
                    overflow hidden
                    text-overflow: ellipsis
                    white-space: nowrap
                span
                    display: block
                    font-size 0.12rem
                    line-height: 0.14rem
                    overflow hidden
                    text-overflow: ellipsis
                    white-space: nowrap
                .btns
                    overflow hidden
                    button
                        width 50%
                        height: 100%
                        background: none
                        border none
                        color #fff
                        font-size 0.24rem
                        cursor pointer
                        &:hover
                            background #0d0a31
                            color red
                &.folder
                    .btns
                        display: none
</style>